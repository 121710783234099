import React from "react";
import illustration from "../../assets/img/illustrations/illustration_01.svg";
import "./style.css";
import {Col, Grid, Row} from "antd";

const {useBreakpoint} = Grid;

export const AboutUsSection = (props) => {
  const screens = useBreakpoint()

  return (
    <section
      id={"aboutUsSection"}
      style={{padding: screens["md"] ? "100px 0" : "60px 0"}}
    >
      <Row style={{marginInline: "auto", width: "min(80%, 1300px)", height: "max(560px, 100%)"}}>
        <Col span={24} sm={24} md={9} lg={8}>
          <div style={{display: "grid", flexDirection: "column", height: "100%"}}>
            <h3
              data-aos="fade-up"
              className={"lText"}
              style={{color: "black", whiteSpace: "nowrap", justifySelf: "flex-start"}}
            >
              ABOUT US
            </h3>
            <div
              data-aos="fade-up"
              data-aos-offset="200"
              className={"aboutUsText"}
              style={{justifySelf: "center"}}
            >
              {!screens["md"] ?
                <div>
                  <div style={{marginTop: 20, color: "#555"}}>
                    Hefeos Labs is a software development company based in Serbia.
                    Specializes in custom end-to-end software solutions.
                  </div>
                  <div style={{marginTop: 20, color: "#555"}}>
                    We have extensive experience in the development of software products of varying complexity.
                    With our in-the-field domain expertise, we bring you innovative and predictable solutions — that deliver results.
                  </div>
                  <div style={{marginTop: 20, color: "#555"}}>
                    We look forward to working with you and helping you achieve your business goals.
                  </div>
                </div>
                :
                <div>
                  <div style={{marginTop: 20, color: "#555"}}>
                    Hefeos Labs is a software development company based in Serbia.
                    Specializes in custom end-to-end software solutions and works for a wide variety of clients,
                    mainly in the industrial sector.
                  </div>
                  <div style={{marginTop: 20, color: "#555"}}>
                    We have extensive experience in the development of software products of varying complexity.
                    With our in-the-field domain expertise, we bring you innovative and predictable solutions — that deliver results.
                    The idea is simple. You’re different.
                    So let’s devise a strategy and construct a solution directly aligned to your specific needs. Together.
                  </div>
                  <div style={{marginTop: 20, color: "#555"}}>
                    {/* We look forward to working with you and helping you achieve your business goals.  */}
                    Please don't hesitate to contact us if you have any questions or would like to discuss your project in more detail.
                    We turn ideas into reality everyday.
                  </div>
                </div>
              }

            </div>
          </div>
        </Col>
        <Col span={24} sm={24} md={{offset: 1, span: 14}} lg={{offset: 2, span: 14}}
             style={{display: "flex", alignItems: "center"}}
        >
          <div style={{width: "100%"}}>
            <div
              data-aos={screens["md"] ? "fade-left" : "fade-right"}
              data-aos-delay="100"
            >
              <img src={illustration} alt={""} width={"100%"} style={{maxHeight: !screens["md"] ? "240px" : "unset"}}/>
            </div>
            <div
              style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginInline: 10}}
              data-aos={"fade-bottom"}
              data-aos-anchor-placement="center"
              data-aos-delay="100"
            >
              <div>
                <div className={"counterText"}>5+</div>
                <div className={"counterText2"}>Years of experience</div>
              </div>
              <div style={{fontFamily: "Oswald"}}>
                <div className={"counterText"}>6+</div>
                <div className={"counterText2"}>Large-scale projects</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}