import React, {useEffect, useState} from "react";
import {Row, Col} from "antd";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Twitter from '@material-ui/icons/Twitter';
import "./style.css";
import {Divider} from "@material-ui/core";
import {useWindowSize} from "../../utill/utillity";

export const ContactSection = (props) => {

  const windowSize = useWindowSize()

  const getBreakpoint = () => {
    if (windowSize.width >= 1500) {
      return "xxl"
    } else if (windowSize.width >= 1250) {
      return "xl"
    } else if (windowSize.width >= 1060) {
      return "lg"
    } else if (windowSize.width >= 900) {
      return "md"
    } else if (windowSize.width >= 760) {
      return "sm"
    }
    return "xs"
  }

  const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint())

  useEffect(() => {
    setCurrentBreakpoint(getBreakpoint);
  }, [windowSize.width])

  const getMapContainerWidth = () => {
    switch (currentBreakpoint) {
      case "xxl":
        return 760
      case "xl":
        return 600
      case "lg":
        return 510
      case "md":
        return 420
      case "sm":
        return 330
      case "xs":
        return "76vw"
      default:
        return 600
    }
  }

  const getMapContainerHeight = () => {
    switch (currentBreakpoint) {
      case "xs":
        return 200
      default:
        return 520
    }
  }

  const [mapContainerWidth, setMapContainerWidth] = useState(getMapContainerWidth());
  const [mapContainerHeight, setMapContainerHeight] = useState(getMapContainerHeight());

  useEffect(() => {
    setMapContainerWidth(getMapContainerWidth())
    setMapContainerHeight(getMapContainerHeight())
  }, [currentBreakpoint])

  return (
    <section id={"contactSection"}>
      <div className={"contactTop"}/>
      <div className={"contactContainer"}>
        <Row style={{marginInline: "auto", width: "min(80%, 1300px)", height: "100%"}}>
          <Col span={currentBreakpoint === "xs" ? 24 : 10}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <Divider
                orientation={"vertical"}
                style={{backgroundColor: "#f5f5f533", width: "2px", height: 350}}
              />
              <div className={"infoSection"}>
                <div data-aos={"fade-up"}>
                  <h3 className={"lText"} style={{marginBottom: 40}}>
                    CONTACT
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%"
                  }}
                  data-aos={"fade-right"}
                >
                  <div>
                    <div className={"contactText"} style={{display: "flex"}}>
                      <LocationOnIcon className={"contactIcon"}/>
                      <div style={{display: "inline-block", whiteSpace: "nowrap"}}>
                        Serbia
                      </div>
                    </div>
                    <div className={"contactText"} style={{marginTop: 20}}>
                      <PhoneIcon className={"contactIcon"}/>
                      <div>+381 697 809 34</div>
                    </div>
                    <div className={"contactText"} style={{marginTop: 20}}>
                      <EmailIcon className={"contactIcon"}/>
                      <div>office@hefeos.com</div>
                    </div>
                    <div className={"contactText"} style={{marginTop: 20}}>
                      <LinkedInIcon className={"contactIcon"}/>
                      <a href="https://www.linkedin.com/company/hefeos-labs" target={"_blank"}><span>LinkedIn</span></a>
                    </div>
                    <div className={"contactText"} style={{marginTop: 20}}>
                      <Twitter className={"contactIcon"}/>
                      <a href="https://twitter.com/hefeos" target={"_blank"}><span>Twitter</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            span={currentBreakpoint === "xs" ? 24 : 14}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              top: currentBreakpoint === "xs" ? 60 : 0
            }}
          >
          </Col>
        </Row>
      </div>
      <div className={"contactBottom"}>
        <div className={"footerText"}>
          © 2023 Hefeos Labs. All Rights Reserved
        </div>
      </div>
    </section>
  )
}
