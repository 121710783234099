import React, { useEffect } from "react";
import "./style.css";
import {getOs} from "../../utill/utillity";

export default function TJSParticles(props) {
  const maxBallsRef = React.createRef();

  useEffect(() => {
    const os = getOs();
    let speedConstant = 1;
    if (os !== "Linux") {
      speedConstant = 0.5;
    }

    maxBallsRef.current = 16 * Math.round(((window.innerWidth - 330)/250) + 1)
    let canvas = document.getElementById('nokey'),
      can_w = parseInt(canvas.getAttribute('width')),
      can_h = parseInt(canvas.getAttribute('height')),
      ctx = canvas.getContext('2d');

    let canvasOverlay = document.getElementById("canvasTracking");
    // let svgOverlay = document.getElementById("svgTracking")
    let headerOverlay = document.getElementById("headerId")

    let entered, left = false;

    let ball = {
        x: 0,
        y: 0,
        vx: 0,
        vy: 0,
        r: 0,
        alpha: 1,
        phase: 0
      },
      ball_color = {
        r: 255,
        g: 215,
        b: 55
      },
      R = 2,
      balls = [],
      alpha_f = 0.03,
      alpha_phase = 0,

    // Line
      link_line_width = 1.4,
      dis_limit = 260,
      add_mouse_point = true,
      mouse_ball = {
        x: 0,
        y: 0,
        vx: 0,
        vy: 0,
        r: 0,
        type: 'mouse'
      };

    // Random speed
    function getRandomSpeed(pos) {
      let min = -1, max = 1;
      switch (pos) {
        case 'top':
          return [speedConstant*randomNumFrom(min, max), speedConstant*randomNumFrom(0.1, max)];
        case 'right':
          return [speedConstant*randomNumFrom(min, -0.1), speedConstant*randomNumFrom(min, max)];
        case 'bottom':
          return [speedConstant*randomNumFrom(min, max), speedConstant*randomNumFrom(min, -0.1)];
        case 'left':
          return [speedConstant*randomNumFrom(0.1, max), speedConstant*randomNumFrom(min, max)];
        default:
          return;
      }
    }

    function randomArrayItem(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    function randomNumFrom(min, max) {
      return Math.random() * (max - min) + min;
    }

    // Random Ball
    function getRandomBall() {
      let pos = randomArrayItem(['top', 'right', 'bottom', 'left']);
      switch (pos) {
        case 'top':
          return {
            x: randomSidePos(can_w),
            y: -R,
            vx: getRandomSpeed('top')[0],
            vy: getRandomSpeed('top')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
        case 'right':
          return {
            x: can_w + R,
            y: randomSidePos(can_h),
            vx: getRandomSpeed('right')[0],
            vy: getRandomSpeed('right')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
        case 'bottom':
          return {
            x: randomSidePos(can_w),
            y: can_h + R,
            vx: getRandomSpeed('bottom')[0],
            vy: getRandomSpeed('bottom')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
        case 'left':
          return {
            x: -R,
            y: randomSidePos(can_h),
            vx: getRandomSpeed('left')[0],
            vy: getRandomSpeed('left')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
          }
      }
    }

    function randomSidePos(length) {
      return Math.ceil(Math.random() * length);
    }

    // Draw Ball
    function renderBalls() {
      Array.prototype.forEach.call(balls, function (b) {
        if (!b.hasOwnProperty('type')) {
          ctx.fillStyle = 'rgba(' + ball_color.r + ',' + ball_color.g + ',' + ball_color.b + ',' + b.alpha + ')';
          ctx.beginPath();
          ctx.arc(b.x, b.y, R, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.fill();
        }
      });
    }

    // Update balls
    function updateBalls() {
      let new_balls = [];
      Array.prototype.forEach.call(balls, function (b) {
        b.x += b.vx;
        b.y += b.vy;

        if (b.x > -(50) && b.x < (can_w + 50) && b.y > -(50) && b.y < (can_h + 50)) {
          new_balls.push(b);
        }

        // alpha change
        b.phase += alpha_f;
        b.alpha = Math.abs(Math.cos(b.phase));
      });

      balls = new_balls.slice(0);
    }

    // Draw lines
    function renderLines() {
      let fraction, alpha;
      for (let i = 0; i < balls.length; i++) {
        for (let j = i + 1; j < balls.length; j++) {

          fraction = getDisOf(balls[i], balls[j]) / dis_limit;

          if (fraction < 1) {
            alpha = (1 - fraction).toString();

            ctx.strokeStyle = 'rgba(180,196,210,' + alpha + ')';
            ctx.lineWidth = link_line_width;

            ctx.beginPath();
            ctx.moveTo(balls[i].x, balls[i].y);
            ctx.lineTo(balls[j].x, balls[j].y);
            ctx.stroke();
            ctx.closePath();
          }
        }
      }
    }

    // calculate distance between two points
    function getDisOf(b1, b2) {
      let delta_x = Math.abs(b1.x - b2.x),
        delta_y = Math.abs(b1.y - b2.y);

      return Math.sqrt(delta_x * delta_x + delta_y * delta_y);
    }

    // add balls if there a little balls
    function addBallIfy() {
      if (balls.length < maxBallsRef.current) {
        balls.push(getRandomBall());
      }
    }

    // Init Balls
    function initBalls(num) {
      for (let i = 1; i <= num; i++) {
        balls.push({
          x: randomSidePos(can_w),
          y: randomSidePos(can_h),
          vx: getRandomSpeed('top')[0],
          vy: getRandomSpeed('top')[1],
          r: R,
          alpha: 1,
          phase: randomNumFrom(0, 10)
        });
      }
    }

    // Init Canvas
    function initCanvas() {
      canvas.setAttribute('width', window.innerWidth);
      canvas.setAttribute('height', window.innerHeight);

      can_w = parseInt(canvas.getAttribute('width'));
      can_h = parseInt(canvas.getAttribute('height'));

      maxBallsRef.current = 16 * Math.round(((window.innerWidth - 330)/250) + 1)
    }

    window.addEventListener('resize', function (e) {
      initCanvas();
    });

    function goMovie() {
      initCanvas();
      initBalls(maxBallsRef.current);
      window.requestAnimationFrame(render);
    }

    // Mouse effect

    const onMouseEnter = (e) => {
      document.getElementById('nokey').focus();
      entered = true;
      left = false;
      balls.push(mouse_ball);
    }

    const onMouseLeave = (e) => {
      document.getElementById('nokey').focus();
      entered = false;
      left = true;
      let new_balls = [];
      Array.prototype.forEach.call(balls, function (b) {
        if (!b.hasOwnProperty('type')) {
          new_balls.push(b);
        }
      });
      balls = new_balls.slice(0);
    }

    const onMouseMove = (e) => {
      document.getElementById('nokey').focus();
      if (!entered) { // Fix za nepojavljivanje tackice za chrome i edge
        balls.push(mouse_ball);
        entered = true;
      }

      let event = e || window.event;
      mouse_ball.x = event.pageX;
      mouse_ball.y = event.pageY;
    }

    headerOverlay.addEventListener('mouseenter', onMouseEnter);
    headerOverlay.addEventListener('mouseleave', onMouseLeave);
    headerOverlay.addEventListener('mousemove', onMouseMove);

    canvasOverlay.addEventListener('mouseenter', onMouseEnter);
    canvasOverlay.addEventListener('mouseleave', onMouseLeave);
    canvasOverlay.addEventListener('mousemove', onMouseMove);

    // svgOverlay.addEventListener('mouseenter', onMouseEnter);
    // svgOverlay.addEventListener('mouseleave', onMouseLeave);
    // svgOverlay.addEventListener('mousemove', onMouseMove);

    // Render
    function render() {
      ctx.clearRect(0, 0, can_w, can_h);
      renderBalls();
      renderLines();
      updateBalls();
      addBallIfy();
      window.requestAnimationFrame(render);
    }

    goMovie();

    return () => {
      headerOverlay.removeEventListener('mouseenter', onMouseEnter);
      headerOverlay.removeEventListener('mouseleave', onMouseLeave);
      headerOverlay.removeEventListener('mousemove', onMouseMove);

      canvasOverlay.removeEventListener('mouseenter', onMouseEnter);
      canvasOverlay.removeEventListener('mouseleave', onMouseLeave);
      canvasOverlay.removeEventListener('mousemove', onMouseMove);

      // svgOverlay.removeEventListener('mouseenter', onMouseEnter);
      // svgOverlay.removeEventListener('mouseleave', onMouseLeave);
      // svgOverlay.removeEventListener('mousemove', onMouseMove);
    }
  }, [])

  return (
    <div id={"canvasTracking"}>
      {props.children}
      <canvas id={"nokey"}
              className={"topContainer"}
              style={{ backgroundImage: "url(/img/blue_gold_hd.webp)"}}
      />
      <div className={"gradientMaskTop"} />
      <div className={"gradientMaskLeft"} />
      <div className={"gradientMaskRight"} />
      <div className={"gradientMaskBottom"} />
    </div>
  )
}




















