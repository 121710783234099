import React, {useEffect, useState} from "react"
import white_logo from "../../assets/img/logos/ver_4_all_white_3.svg"
import hefeos_labs_logo from "../../assets/img/logos/HEFEOS-LABS-WHITE.svg"
import Button from "@material-ui/core/Button";
import {scrollToElement, useWindowSize} from "../../utill/utillity";
import blueLine from "../../assets/img/blue_line.svg";
import whiteLine from "../../assets/img/white_line.svg";
import "./style.css";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {Drawer} from "antd";

export const CustomHeader = (props) => {
  const [headerWidth, setHeaderWidth] = useState("80%");
  const [replaceWithMenu, setReplaceWithMenu] = useState(false);
  const [visibleDrawer ,setVisibleDrawer] = useState(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width >= 1270) {
      setHeaderWidth("80%");
      setReplaceWithMenu(false);
    }
    else if (windowSize.width < 1270 && windowSize.width >= 1070) {
      setHeaderWidth("96%");
      setReplaceWithMenu(false);
    }
    else {
      setReplaceWithMenu(true);
      setHeaderWidth("90%");
    }
  }, [windowSize.width])


  let doc = document.documentElement;
  let w = window;
  let prevScroll = w.scrollY || doc.scrollTop;
  let curScroll;
  let direction = 0;
  let prevDirection = 0;
  const header = document.getElementById("headerId");

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  const onScroll = () => {
    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    }
    else if (curScroll < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeaderOnScroll(direction, curScroll);
    }

    if (window.scrollY + window.innerHeight >= document.body.scrollHeight)
    {
      toggleHeaderOnScroll(1, curScroll);
    }


    prevScroll = curScroll;
  }

  const toggleHeaderOnScroll = (direction, curScroll) => {
    if (direction === 2 && curScroll > 52) {
      header.classList.add('hide');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.classList.remove('hide');
      prevDirection = direction;
    }
  }

  const onDrawerLinkClick = (elementId) => {
    scrollToElement(elementId);
    setVisibleDrawer(false);
  }

  return (
    <div id={"headerId"}>
      <div className={"headerContent"} style={{ width: headerWidth }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={hefeos_labs_logo} alt={<span>HEFEOS LABS</span>} height={45} style={{marginTop: "0.7em"}}/>
          {windowSize.width > 436 &&
            <div style={{ marginLeft: 32, opacity: 0.75 }}>
              <img src={blueLine} alt={""} height={70}/>
            </div>
          }
        </div>

        <div className={"headerLinks"}>
          <div style={{ marginRight: 32, opacity: 0.75, display: windowSize.width <= 530 ? "none" : "inherit" }}>
            <img src={whiteLine} alt={""} height={70}/>
          </div>

          {replaceWithMenu ?
            <div>
              <IconButton
                aria-label={"open drawer"}
                onClick={() => {
                  setVisibleDrawer(prev => !prev);
                }}
                style={{zIndex: 2000, color: "#aaa"}}
              >
                <MenuIcon/>
              </IconButton>
            </div>
          :
            <div>
              <Button onClick={() => {scrollToElement("HomeId")}}>
                <span className={"headerLinkText"}>Home</span>
              </Button>

              <Button onClick={() => {scrollToElement("aboutUsSection")}}>
                <span className={"headerLinkText"}>About</span>
              </Button>

              <Button onClick={() => {scrollToElement("projectsSection")}}>
                <span className={"headerLinkText"}>Projects</span>
              </Button>

              <Button onClick={() => {scrollToElement("servicesSection")}}>
                <span className={"headerLinkText"}>Services</span>
              </Button>

              <Button onClick={() => {scrollToElement("clientsSection")}}>
                <span className={"headerLinkText"}>CLIENTS</span>
              </Button>

              <Button onClick={() => {scrollToElement("contactSection")}}>
                <span className={"headerLinkText"}>Contact</span>
              </Button>
            </div>
          }
        </div>

        <Drawer
          className={"headerDrawer"}
          title={
            <div align={"right"} style={{ width: "100%" }}>
              <IconButton
                aria-label={"open drawer"}
                onClick={() => {
                  setVisibleDrawer(prev => !prev);
                }}
                style={{zIndex: 2000, color: "#aaa"}}
              >
                <MenuIcon/>
              </IconButton>
            </div>
          }
          placement={"right"}
          closable={false}
          onClose={() => {
            setVisibleDrawer(false);
          }}
          visible={visibleDrawer}
          key={"right"}
          headerStyle={{ padding: "0 26px 0 0", height: 70, backgroundColor: "black", border: "none", width: "100%"  }}
          drawerStyle={{ backgroundColor: "#000", display: "flex", alignItems: "center" , textAlign: "center", flexDirection: "column"}}
        >
          <div className={"linksContainer"}>
            <Button onClick={() => {onDrawerLinkClick("HomeId")}}>
              <div className={"drawerLink"} style={{ marginTop: 0 }}>Home</div>
            </Button>

            <Button onClick={() => {onDrawerLinkClick("aboutUsSection")}}>
              <div className={"drawerLink"}>About</div>
            </Button>

            <Button onClick={() => {onDrawerLinkClick("projectsSection")}}>
              <div className={"drawerLink"}>Projects</div>
            </Button>

            <Button onClick={() => {onDrawerLinkClick("servicesSection")}}>
              <div className={"drawerLink"}>Services</div>
            </Button>

            <Button onClick={() => {onDrawerLinkClick("clientsSection")}}>
              <div className={"drawerLink"}>Clients</div>
            </Button>

            <Button onClick={() => {onDrawerLinkClick("contactSection")}}>
              <div className={"drawerLink"}>Contact</div>
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  )
}