import React from "react";
import {Col, Row, Grid, Card} from "antd";
import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gelsoLogo from "../../assets/img/logos/clientLogos/gelso.webp";
import easttecLogo from "../../assets/img/logos/clientLogos/easttec.webp";
import pavicLogo from "../../assets/img/logos/clientLogos/pavic.webp";

const {useBreakpoint} = Grid;

let settings = {
  className: "sliderContent",
  dots: true,
  speed: 500,
  autoplay: false,
  lazyLoad: true,
  infinite: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1152,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};

const ClientCard = (props) => {
  const {
    name, description,
    logo, link
  } = props;

  return (
    <Card className={"clientCard"}
          onClick={() => {
            window.open(link, "_blank");
          }}
    >
      <div style={{height: 120, display: "flex", justifyContent: "center"}}>
        <div className={"clientImgContainer"}>
          <img src={logo} alt={name} className={"clientImg"}/>
        </div>
      </div>
      <div className={"clientDescription"}>
        {description}
      </div>
    </Card>
  )
}

export const ClientsSection = (props) => {
  const screens = useBreakpoint();

  return (
    <section
      id={"clientsSection"}
      style={{ justifyContent: !screens["md"] ? "flex-start" : "center" }}
    >
      <div
        className={"picBackCareer"}
        style={{backgroundImage: "url(/img/graf.webp)", width: screens["md"] ? "64%" : "100%"}}
      />
      <Row
        gutter={[0, 32]}
        style={{marginInline: "auto", width: "min(80%, 1300px)", height: screens["md"] ? "100%" : "unset", display: "flex"}}
      >
        {screens["md"] ?
          <Col span={8}>
            <h3
              className={"clientTitle"}
              style={{top: screens["md"] ? 100 : 60}}
              data-aos={"fade-right"}
              data-aos-anchor-placement={"top-bottom"}
            >
              CLIENTS
            </h3>
            <div
              className={"careerText centeredCol"}
              style={{ maxWidth: 300 }}
              data-aos="fade-right"
            >
              <div style={{marginTop: 40, color: "#aaa"}}>
                At Hefeos Labs, we pride ourselves on our customer-centric approach.
                We work closely with our clients to understand their business goals and needs,
                and we craft custom software solutions that help them achieve their objectives.
                <br/><br/>
                We believe in transparency and open communication,
                and we strive to build long-term partnerships with our clients.
              </div>
            </div>
          </Col>
          :
          <Col span={24}>
            <h3
              className={"projectsTitle"}
              style={{ height: 80, display: "flex", alignItems: "flex-end" }}
              data-aos="fade-right"
              data-aos-anchor-placement={"top-bottom"}
            >
              CLIENTS
            </h3>
            <div
              className={"careerText"}
              style={{marginBottom: 20}}
              data-aos="fade-right"
            >
              <div style={{marginTop: 40, color: "#aaa"}}>
                At Hefeos Labs, we pride ourselves on our customer-centric approach.
                <br/><br/>
                We work closely with our clients to understand their business goals and needs,
                and we craft custom software solutions that help them achieve their objectives.
                <br/><br/>
                We believe in transparency and open communication,
                and we strive to build long-term partnerships with our clients.
              </div>
            </div>
          </Col>
        }
        <Col
          span={24}
          md={16}
          style={{display: "flex", alignItems: screens["md"] ? "center" : "flex-start", padding: screens["md"] ? "0 0 0 50px" : "0 0 0 0"}}
        >
          <div className={"sliderContainer"}>
            <Slider {...settings}>
              <ClientCard
                name={"Gelso"}
                logo={gelsoLogo}
                link={"https://www.gelso.ch/en_GB/"}
                description={
                  <span>
                    <b>Gelso</b> is contract manufacturers for the SME industry. Company supply a wide range of machine components.
                  </span>
                }
              />
              <ClientCard
                name={"Easttec"}
                logo={easttecLogo}
                link={"https://easttec.rs/en/home/"}
                description={
                  <span>
                    <b>Easttec</b> provides installation, service and technical support services for wide range of lasers, as well as personnel training.
                  </span>
                }
              />
              <ClientCard
                name={"Pavic d.o.o."}
                logo={pavicLogo}
                link={"https://pavicdoo.rs/en/home-english-2/"}
                description={
                  <span>
                    With more than 30 years of experience in the market, <b>Pavic</b> is qualified to produce everything their customers need.
                  </span>
                }
              />
            </Slider>
          </div>
        </Col>
      </Row>
    </section>
  )
}

