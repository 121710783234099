import React from "react";5
import {Col, Row, Grid} from "antd";

import "./style.css";
import illustration from "../../assets/img/illustrations/illustration_02.svg";
import {useWindowSize} from "../../utill/utillity";

const { useBreakpoint } = Grid;

export const ServicesSection = (props) => {
  const screens = useBreakpoint();
  const windowSize = useWindowSize();

  const getImgMaxHeight = () => {
    if (screens["md"])
      return "unset"
    else if (screens["sm"])
      return "300px"
    else if (windowSize.width > 460)
      return "220px"
    else if (windowSize.width > 420)
      return "180px"
    else
      return "140px"
  }

  return (
    <section
      id={"servicesSection"}
      style={{padding: screens["md"] ? "100px 0" : "60px 0"}}
    >
      {screens["md"] ?
        <Row style={{ marginInline: "auto", width: "min(80%, 1300px)", height: "max(560px, 100%)"}}>
          <Col span={24} sm={24} md={13} lg={14}
               style={{ display: "flex", alignItems: "center" }}
          >
            <div data-aos="fade-right" style={{ width: "100%" }}>
              <img src={illustration} alt={""} width={"100%"}/>
            </div>
          </Col>
          <Col span={24} sm={24} md={{offset: 1, span: 9}} lg={{offset: 1, span: 9}}>
            <div style={{ display: "grid", flexDirection: "column", height: "100%"}}>
              <h3
                className={"lText"}
                style={{ color: "black", whiteSpace: "nowrap", justifySelf: "flex-start" }}
                data-aos="fade-up"
              >
                SERVICES
              </h3>
              <div
                className={"aboutUsText"}
                style={{ justifySelf: "center"}}
                data-aos="fade-up"
              >
                <div>
                  <div  style={{ marginTop: 20, color: "#555" }}>
                    Officially founded in 2021, Hefeos Labs has consistently delivered innovative software solutions to businesses of
                    all sizes. We are a team of experienced and dedicated professionals 
                    who are committed to delivering high-quality software products to our clients.
                  </div>
                  <div style={{ marginTop: 20, color: "#555" }}>
                    Our team has a wealth of experience in various technologies and programming languages,
                    including Java, JavaScript, Python, C++, and many more. We are always
                    up-to-date with the latest technologies and trends, ensuring that our clients receive the best
                    possible solutions for their specific needs.
                  </div>
                  <div style={{ marginTop: 20, color: "#555" }}>
                    In addition to custom software development,
                    we also offer a range of services including software maintenance, integration, and hardware solutions.
                    Our team is skilled at working on both desktop and mobile applications,
                    and we have a strong track record of delivering successful projects on time and within budget.
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        :
        <Row style={{ marginInline: "auto", width: "min(80%, 1300px)", height: "max(560px, 100%)"}}>
          <Col span={24} style={{ height: 100 }}>
            <h3
              className={"lText"}
              style={{ color: "black" }}
              data-aos="fade-up"
            >
              SERVICES
            </h3>
          </Col>
          <Col span={24} sm={24}>
            <div
              className={"aboutUsText"}
              style={{ marginBottom: 20 }}
              data-aos="fade-up"
            >
              <div  style={{ marginTop: 20, color: "#555" }}>
                Officially founded in 2021, Hefeos Labs is a team of experienced and dedicated professionals
                who are committed to delivering high-quality software products to our clients.
              </div>
              <div style={{ marginTop: 20, color: "#555" }}>
                We are always up-to-date with the latest technologies and trends, ensuring that our clients receive the best
                possible solutions for their specific needs.
              </div>
              <div style={{ marginTop: 20, color: "#555" }}>
                In addition to custom software development,
                we also offer a range of services including software maintenance, integration, and hardware solutions.
              </div>
              <div style={{ marginTop: 20, color: "#555" }}>
                Our team is skilled at working on both desktop and mobile applications,
                and we have a strong track record of delivering successful projects on time and within budget.
              </div>
            </div>
          </Col>
          <Col span={24} sm={24}
               style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <div style={{ width: "min(480px, 100%)", height: "100%" }}
                 data-aos="fade-right"
            >
              <img
                src={illustration}
                alt={""}
                width={"100%"}
                style={{maxHeight: getImgMaxHeight()}}
              />
            </div>
          </Col>
        </Row>
      }
    </section>
  )
}