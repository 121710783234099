import React, {useState} from "react";
import "./style.css";
import {Col, Grid, Radio, Row, Space} from "antd";
import Button from "@mui/material/Button";
import arrow from "../../assets/img/arrow.svg";

const {useBreakpoint} = Grid;

const ProjectTitle = (props) => {
  return (
    <div className={"projectTitle"}>
      {props.children}
    </div>
  )
}

const ProjectDescription = (props) => {
  return (
    <div className={"projectDescription"}>
      {props.children}
    </div>
  )
}

const ProjectIterator = (props) => {

  const {
    screens, selectedProj,
    setSelectedProj
  } = props;

  if (screens["md"]) {
    return null
  } else {
    return (
      <div className={"projectIterator"}>
        <div className={"sText"}>{selectedProj + 1}/3</div>
        <Button
          style={{
            aspectRatio: 1,
            fontSize: 24,
            color: "#aaa",
            paddingLeft: 0,
            marginLeft: -14,
            height: 64,
            borderRadius: "50%"
          }}
          onClick={() => {
            setSelectedProj(prev => {
              if (prev < 2)
                return prev + 1
              return 0
            })
          }
          }
        >
          <img src={arrow} alt={"--->"} width={40} style={{marginLeft: 12}}/>
        </Button>
      </div>
    )
  }
}

export const ProjectsSection = (props) => {
  const [selectedProj, setSelectedProj] = useState(0);

  const screens = useBreakpoint();

  return (
    <section id={"projectsSection"}>
      <div
        className={"picBackProjects"}
        style={{backgroundImage: "url(/img/ruka.webp)", width: screens["md"] ? "64%" : "100%"}}
      />
      <Row style={{marginInline: "auto", width: "min(80%, 1300px)", height: "100%"}}>
        {screens["md"] &&
        <Col span={8}>
          <h3
            className={"projectsTitle"}
            style={{top: screens["md"] ? 100 : 60}}
            data-aos="fade-right"
            data-aos-anchor-placement={"top-bottom"}
          >
            PROJECTS
          </h3>
          <div className={"centeredCol"} style={{justifyContent: "space-evenly"}}>
            <Radio.Group
              buttonStyle={"solid"}
              onChange={(e) => {
                setSelectedProj(e.target.value)
              }}
              defaultValue={selectedProj}
              className={"projectGroup"}
              data-aos="fade-right"
            >
              <Space direction="vertical">
                <Radio.Button value={0} style={{boxShadow: "none"}}>
                    <span className={"mText"}>
                      <span className={"line"}>|</span>Hefeos
                    </span>
                </Radio.Button>
                <Radio.Button value={1} style={{boxShadow: "none"}}>
                    <span className={"mText"}>
                      <span className={"line"}>|</span>Step2Real
                    </span>
                </Radio.Button>
                <Radio.Button value={2} style={{boxShadow: "none"}}>
                    <span className={"mText"}>
                      <span className={"line"}>|</span>Aigidos
                    </span>
                </Radio.Button>
              </Space>
            </Radio.Group>
          </div>
        </Col>
        }
        <Col
          span={24}
          md={16}
          style={{display: "grid", padding: screens["md"] ? "0 0 0 50px" : "0 0 0 0"}}
        >
          <Row>
            <Col span={24} style={{ minHeight: 80, maxHeight: 80, display: "flex", alignItems: "flex-end"}}>
              {!screens["md"] &&
              <h3
                className={"projectsTitle"}
                data-aos="fade-right"
                data-aos-anchor-placement={"top-bottom"}
              >
                PROJECTS
              </h3>
              }
            </Col>
            <Col span={24} style={{display: "flex"}}>
              <div

              >
                {selectedProj === 0 &&
                <div
                  data-aos={"fade-bottom"}
                  data-aos-anchor-placement="center"
                  data-aos-delay="100"
                  style={{height: "max(60%, 580px)"}}>
                  <ProjectTitle >
                    <a className="whiteTitle" href="https://hefeos.com/" target="_blank">Hefeos IQT</a>
                  </ProjectTitle>
                  <ProjectDescription>
                    <div style={{marginTop: screens["md"] ? 40 : 10, color: "#aaa"}}>
                      Hefeos Instant Quoting Tool (IQT) is a powerful and versatile cloud-based tool for manufacturers looking to streamline their
                      processes and get the most out of their resources.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      One of the key features of Hefeos is our real-time pricing system.
                      As you change materials, lead time, and other factors,
                      our platform will automatically update the price so you know exactly what you're paying for.
                      There are no hidden costs with Hefeos -
                      our instant quotes include shipping and customs up front, so you can be confident you're getting the best price possible.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      In addition to our real-time pricing system,
                      Hefeos also offers a nesting feature that is perfect for laser cutters, plasma cutters, and other CNC machines.
                      This feature allows you to pack your parts into a compact area, saving both material and time.
                      This is a great way to optimize your manufacturing process and increase efficiency.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      But Hefeos isn't just about making the manufacturing process more efficient -
                      it's also about making it more accessible. Our cloud-based platform allows you to access your manufacturing resources from anywhere,
                      at any time. This means you can work on your designs and get instant quotes no matter where you are,
                      as long as you have an internet connection.
                    </div>
                  </ProjectDescription>
                  <ProjectIterator
                    screens={screens}
                    selectedProj={selectedProj}
                    setSelectedProj={setSelectedProj}/>
                </div>
                }
                {selectedProj === 1 &&
                <div
                  data-aos={"fade-bottom"}
                  data-aos-anchor-placement="center"
                  data-aos-delay="100"
                  style={{height: "max(60%, 580px)"}}>
                  <ProjectTitle>
                    <a className={"whiteTitle"} href={"https://step2real.ch/login"} target={"_blank"}>
                      Step2Real
                    </a>
                  </ProjectTitle>
                  <ProjectDescription>
                    <div style={{marginTop: screens["md"] ? 40 : 10, color: "#aaa"}}>
                      Gelso Step2Real is cloud manufacturing platform connecting engineering companies with the
                      manufacturing market.
                      Our innovative platform allows engineering teams to easily design and prepare files for a wide range
                      of manufacturing methods,
                      all from the convenience of the cloud.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      With Step2Real, gone are the days of spending hours gathering quotes and comparing options.
                      Our algorithm does the work for you, quickly selecting the best manufacturing partner based on price,
                      location, required materials, and manufacturing methods.
                      This allows engineers and purchasing managers to focus on what they do best -
                      making informed decisions and bringing products to market faster.
                    </div>
                  </ProjectDescription>
                  <ProjectIterator
                    screens={screens}
                    selectedProj={selectedProj}
                    setSelectedProj={setSelectedProj}/>
                </div>
                }
                {selectedProj === 2 &&
                <div
                  data-aos={"fade-bottom"}
                  data-aos-anchor-placement="center"
                  data-aos-delay="100"
                  style={{height: "max(60%, 580px)"}}>
                  <ProjectTitle>
                    Aigidos
                  </ProjectTitle>
                  <ProjectDescription>
                    <div style={{marginTop: screens["md"] ? 40 : 10, color: "#aaa"}}>
                      Aigidos is a powerful employee tracking software that helps you understand how your employees use their time and provides valuable behavior analytics.
                      Aigidos can help you optimize your team's productivity and improve your bottom line.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      Our software is designed to give you a unique perspective on your employees' productivity, daily activities, and timesheets.
                      With Aigidos, you can track your employees' work both online and offline, giving you a complete view of their activities.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      One of the key benefits of Aigidos is that it helps you identify areas of inefficiency and opportunities for improvement.
                      By analyzing your employees' work patterns and behaviors,
                      Aigidos can provide valuable insights into how your team is working and where you can make changes to increase productivity and efficiency.
                    </div>
                    <div style={{marginTop: 20, color: "#aaa"}}>
                      In addition to tracking employee activity, Aigidos also offers a range of features to help you manage and organize your team.
                    </div>
                  </ProjectDescription>
                  <ProjectIterator
                    screens={screens}
                    selectedProj={selectedProj}
                    setSelectedProj={setSelectedProj}
                  />
                </div>
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
}