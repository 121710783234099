import React, {useEffect} from "react";
import "./style.css";
import "aos/dist/aos.css";
import TJSParticles from "../../components/TJSParticles/TJSParticles";
import dots from "../../assets/img/dots.svg";
import {Divider} from "antd";
import {ProjectsSection} from "../../sections/ProjectsSection/ProjectsSection";
import {AboutUsSection} from "../../sections/AboutUsSection/AboutUsSection";
import {CustomHeader} from "../../components/CustomHeader/CustomHeader";
import {ServicesSection} from "../../sections/ServicesSection/ServicesSection";
import {ContactSection} from "../../sections/ContactSection/ContactSection";
import Aos from "aos";
import {ClientsSection} from "../../sections/ClientsSection/ClientsSection";
import { BackToTop } from "material-ui-back-to-top"

export default function HefeosPage(props) {

  useEffect(() => {
    Aos.init({
      duration: 500,
      once: true
    });
  }, []);


  return (
    <div style={{ minWidth: 340 }}>
      <CustomHeader />

      <div id={"HomeId"} style={{ backgroundColor: "#21212b"}}>
        <TJSParticles>
          <div className={"fade"} style={{ height: "100%"}}/>
          <div className={"topContainerContent"}>
            <div style={{ zIndex: 6, paddingTop: "min(10vw, 100px)"}}>
              <h1 className={"xlText"} style={{ textShadow: "1px 1px 4px rgb(145, 145, 145)", userSelect: "none" }}>
                Turning ideas<br/>into reality
              </h1>
              <Divider style={{width: "70%", minWidth: "0%", margin: "8px 0 8px 0", borderColor: "#aaa"}}/>
              <h2
                className={"mText descriptionText"}
                style={{ color: "#21ade5", marginTop: 10, userSelect: "none" }}
              >
                Building enterprise level CAD <br/>
                and automation software

              </h2>
              <div style={{ position: "relative", right: 12, top: 48 }}>
                <img src={dots} alt={""} width={150}/>
              </div>
            </div>
          </div>
        </TJSParticles>
      </div>

      <div className={"mainContainer"}>
        <AboutUsSection />
        <ProjectsSection />
        <ServicesSection />
        <ClientsSection />
        <ContactSection />

        <div className={"backToTopBtn"}>
          <BackToTop disableSmoothScroll={false}/>
        </div>

      </div>
    </div>
  );
}
