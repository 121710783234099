import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";
import "./index.css";
import "antd/dist/antd.min.css"

// pages for this product
import HefeosPage from "./views/HefeosPage/HefeosPage";
// import {TestPage} from "./views/TestPage/TestPage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/*<Route path="/test" component={TestPage} />*/}
      <Route path="/" component={HefeosPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
